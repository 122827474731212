@import "styles/variables";

.input {
  height: 68px;
  border-radius: 5px;
  border: solid 1px $input-border-color;
  background-color: $input-bg-color;

  font-size: 22px;
  font-weight: 300;

  padding: 11px 20px;

  transition: all .3s $easing;

  &:hover {
    border-color: darken($input-border-color, 15);
  }

  &:focus {
    outline: none;
    border-color: darken($input-border-color, 15);
    background-color: $inverse-color;
  }

  &::placeholder {
    font-style: italic;
  }

  @media screen and (max-width: 620px) {
    height: 60px;
    font-size: 16px;
  }

  @media screen and (max-width: 470px) {
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }
}


