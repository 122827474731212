@import "styles/variables";
@import "styles/mixins";

.carousel {
  position: relative;
  max-width: 100vw;
  min-height: 410px;
  overflow: hidden;
  padding: 40px 0 120px;

  &__container {
    @include container;
  }

  &__list {

    &-item {
      width: 31%;
      height: 210px;
      border-radius: 30px;
      box-shadow: 0 12px 20px 0 rgba(65, 95, 157, 0.2);
      background-color: $inverse-color;
      padding: 30px 20px;
      display: inline-flex;
      align-items: flex-start;
      font-weight: $font-light;

      &-text {
        margin-left: 10px;
        text-align: right;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  &__controls {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;

    &-arrow {
      position: absolute;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      @include squareSize(210px);
      border-radius: 50%;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
      background-color: rgba(255, 255, 255, 0.75);
      opacity: .8;
      transition: opacity .2s ease-out;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      &[disabled] {
        opacity: 0;
      }

      &-icon {
        position: relative;
        bottom: 2px;
        display: inline-block;
        width: 25px;
        height: 44px;
      }

      &--left {
        left: -105px;
      }

      &--left &-icon {
        transform: rotate(180deg);
        left: 42px;
      }

      &--right {
        right: -105px;
      }

      &--right &-icon {
        right: 42px;
      }
    }
  }

  /*
    FLICKITY OVERWRITES
   */
  .flickity-viewport {
    overflow: visible;
  }

  .flickity-page-dots {
    top: calc(100% + 45px);

    .dot {
      @include squareSize(12px);
      background-color: #b6d7de;

      &.is-selected {
        background-color: $secondary-color;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    &__list {

      &-item {
        width: 48%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    min-height: auto;
    margin-top: 0;
    padding-top: 30px;
    padding-bottom: 86px;

    &__list {

      &-item {
        width: 100%;
        height: 160px;
        padding: 14px 20px;
      }
    }

    &__controls {
      display: none;
    }

    .flickity-page-dots {
      top: calc(100% + 25px);

      .dot {
        margin: 0 5px 5px;
      }
    }
  }
}
