$base-color: #233b6e;
$secondary-color: #3e75c8;
$light-blue: #0086d0;

$error-color: #f65e64;
$emphasize-color: #fd7014;

$inverse-color: #fff;
$inverse-color-secondary: #7ca5e3;

$input-bg-color: #f9f9f9;
$input-border-color: #b0d1e9;

$base-font-size: 20px;
$em-font-size: 1.3rem;   // 26px

$font-light: 300;
$font-regular: 400;
$font-bold: 700;

$easing: cubic-bezier(0.0, 0.0, 0.2, 1);
