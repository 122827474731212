@import "styles/variables";
@import "styles/mixins";

.header {
  color: $inverse-color;

  &__wrapper {
    background-image: linear-gradient(to right, #3e75c8, #233b6e);
    height: 100%;
    z-index: 100;
  }

  &__container {
    @include container;
    position: relative;
    height: 100%;
  }

  &__arrows-bg {
    @include stretchAbsolute;
    display: flex;
    align-items: center;
    margin: 20px 0;
    pointer-events: none;

    &-img {
      width: 100%;
      height: 100%;
      max-height: 720px;
      max-width: 920px;
      background: transparent url(/assets/top-bg-arrows.svg) center/contain no-repeat;
      position: relative;
      right: -10px;
    }
  }

  &__title {
    display: flex;
    justify-content: center;
    padding-top: 11vh;

    &-content {
      position: relative;
    }

    &-text {
      position: absolute;
      right: calc(100% + 14px);
      bottom: 0;
      font-size: 40px;
      font-weight: $font-light;
      white-space: nowrap;
      opacity: 1;
      transition: opacity .2s .3s ease-out;
    }
  }

  &__content {
    position: absolute;
    top: 47%;
    left: 70px;
    right: 20px;

    &-line {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:first-child {
        margin-bottom: 40px;
      }
    }

    &-col {
      text-align: right;
      font-size: 30px;
      font-weight: $font-light;
      line-height: 1.33;

      &-text:first-child {
        margin-right: 130px;
      }

      &:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &__btn-icon {
    display: none;
  }

  &__sub-content {
    text-align: right;
    color: $inverse-color-secondary;
    font-size: $em-font-size;
    font-weight: $font-light;
    line-height: 1.77;

    &-link {
      color: inherit;
      text-decoration: none;
    }
  }

  &__icon-btn {
    color: #233B6F;
    opacity: .5;
    cursor: pointer;
    height: 70px;
    width: 40px;
    transform: rotate(90deg);
    transition: opacity .2s ease-out;
    position: absolute;
    bottom: 30px;
    left: calc(50% - 20px);

    &:hover {
      opacity: .8;
    }
  }

  &__nav {
    position: absolute;
    top: calc(50% - 60px);
    left: calc(50% - 48vw);
    z-index: 200;

    &-link {
      display: block;
      @include squareSize(30px);
      padding: 5px;
      position: relative;

      &::before {
        content: '';
        @include stretchAbsolute(5px);
        border-radius: 50%;
        background-color: #415f9d;
        transition: background-color .2s ease-out;
      }

      &:not(&--active):hover::before {
        background-color: lighten(#415f9d, 15);
      }

      &--active::before {
        background-color: #daebee;
      }
    }
  }

  &--shrink & {

    &__wrapper {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 125px !important;
    }

    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__title-content {
      margin-left: 30px;
    }

    &__title-text {
      position: absolute;
      opacity: 0;
      visibility: hidden;
    }

    &__arrows-bg,
    &__content-col:not(:last-child),
    &__icon-btn {
      display: none;
    }

    &__title {
      padding-top: 0;
      justify-content: flex-start;
    }

    &__logo {
      width: 180px;
      height: 79px;
    }

    &__content {
      position: static;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      &-line:first-child {
        margin-bottom: 0;
        margin-left: 30px;
      }
    }

    &__sub-content {
      font-size: $base-font-size;
    }

    &__nav {
      top: calc(50% - 50px);
      left: calc(50% - 49vw);

      &-link {
        @include squareSize(25px);
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &__arrows-bg {
      justify-content: center;

      &-img {
        position: static;
        background-image: url(/assets/mobile-bg-arrows.svg);
        min-width: 240px;
        min-height: 245px;
        height: 60%;
      }
    }

    &__title {
      padding-top: 5vh;

      &-content {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
      }

      &-text {
        position: static;
        font-size: 36px;
        margin-top: 20px;
      }
    }

    &__content {
      top: 37%;
      left: 20px;

      &-line {

        &:first-child {
          flex-direction: column;
          margin-bottom: 20px;
        }

        &:last-child {
          justify-content: center;
        }
      }


      &-col {

        &-text:first-child {
          margin-right: 0;
        }

        &:first-child {
          flex-direction: column;
          align-items: center;
          text-align: center;
          margin-bottom: 10vh;
        }
      }
    }

    &__sub-content {
      text-align: center;
    }

    &--shrink & {

      &__nav {
        left: 15px;
      }
    }
  }

  @media screen and (max-width: 800px), screen and (max-height: 400px) {

    &--shrink & {

      &__content-line:first-child {
        margin-left: 15px;
      }

      &__content {
        margin: 0;
        padding: 0;
      }

      &__wrapper {
        height: 60px !important;
      }

      &__btn,
      &__content-col {
        font-size: 14px;
      }

      &__sub-content {
        font-size: 11px;
        line-height: 1.31;
        text-align: right;
      }

      &__title-content {
        margin: 0;
      }

      &__logo {
        width: 90px;
      }

      &__nav {
        display: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    position: static;
    height: auto;

    &__arrows-bg {
      align-items: flex-start;
      top: 120px;

      &-img {
        width: 240px;
        height: 245px;
      }
    }

    &__content {
      position: static;
      margin: 84px 0;

      &-col:first-child {
        margin-bottom: 140px;
      }
    }

    &__content-col,
    &__sub-content {
      font-size: 16px;
    }

    &__title {

      &-text {
        font-size: 18px;
      }
    }

    &__logo {
      width: 116px;
      height: 51px;
    }

    &__icon-btn {
      width: 25px;
      height: 43px;
      left: calc(50% - 12px);
    }

    &__nav {
      display: none;
    }
  }

  @media screen and (max-width: 460px) {
    &--shrink & {

      &__btn {
        padding: 7px 9px 8px;

        &-text {
          display: none;
        }

        &-icon {
          display: inline;
        }
      }
    }
  }
}
