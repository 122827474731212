@import 'styles/variables';
@import 'styles/mixins';

.technologies {
  margin-bottom: 62px;

  &__container {
    @include container;
  }

  &__title {
    font-size: 44px;
    font-weight: $font-light;
    line-height: 0.91;
    text-align: center;
    color: $secondary-color;
    margin-bottom: 102px;
  }

  &__header {
    margin-bottom: 60px;
  }

  &__sub-title {
    font-size: 36px;
    font-weight: $font-bold;
    line-height: 1.39;
    margin-bottom: 10px;
  }

  &__desc {
    font-size: 26px;
    font-weight: $font-light;
    line-height: 1.15;
  }

  &__wrapper {
    overflow: auto;
  }

  &__table {
    table-layout: fixed;
    width: 100%;
    min-width: 1060px;
    font-size: $em-font-size;
    font-weight: $font-light;
    text-align: left;
    border-collapse: collapse;

    &-header {
      text-align: left;
    }

    &-row:not(:last-child) {
      border-bottom: solid 1px #b6d7de;
    }

    &-em {
      padding: 18px 0 20px;
      font-weight: $font-regular;
    }
  }

  @media screen and (max-width: 768px) {
    &__title {
      font-size: 20px;
      margin-bottom: 40px;
    }

    &__header {
      margin-bottom: 50px;
    }

    &__sub-title,
    &__desc,
    &__table {
      font-size: 1rem;
    }

    &__table {
      table-layout: initial;
      min-width: 400px;

      &-row td {
        width: 100px;
      }

      &-row td#{&}-em {
        width: 150px;
      }
    }
  }
}
