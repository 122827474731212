@mixin stretchAbsolute($offset: 0) {
  position: absolute;
  top: $offset;
  bottom: $offset;
  left: $offset;
  right: $offset;
}

@mixin squareSize($value) {
  width: $value;
  height: $value;
}

@mixin container() {
  max-width: calc(1280px + 20px * 2);
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
