@import "styles/variables";
@import "styles/mixins";

.feedback-modal {
  position: fixed;
  top: 100%;
  left: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;


  &__backdrop {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.15);
    @include stretchAbsolute();
  }

  &__content {
    display: inline-block;
    vertical-align: middle;
    background-color: #fff;
    border-radius: 5px;
    transform: translateY(-30px);
    padding: 30px 30px 35px;
    opacity: 0;
    width: 100%;
    max-width: 750px;
  }

  &--opened {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &--opened & {

    &__backdrop {
      opacity: 1;
      transition: opacity .3s $easing;
    }

    &__content {
      opacity: 1;
      transform: translateY(0);
      transition: all .3s .3s $easing;
    }
  }

  &--closing & {

    &__backdrop {
      opacity: 0;
      transition: all .3s .3s $easing;
    }

    &__content {
      opacity: 0;
      transform: translateY(30px);
      transition: all .3s $easing;
    }
  }

  &__close {
    display: inline-flex;
    padding: 5px;
    cursor: pointer;
    opacity: .7;
    transition: all .3s $easing;
    &:hover {
      opacity: 1;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;

    &-title {
      font-size: 30px;
      font-weight: 300;
      line-height: 1.33;
      color: $emphasize-color;
      text-align: left;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;

    &-input:not(:last-of-type) {
      margin-bottom: 20px;
    }

    &-controls {
      position: relative;
      margin-top: 45px;
      text-align: center;
    }

    &-errors {
      position: absolute;
      color: $error-color;
      font-size: 16px;
      bottom: calc(100% + 10px);
    }
  }

  @media screen and (max-width: 750px) {

    &__content {
      border-radius: 0;
    }
  }

  @media screen and (max-width: 620px) {
    &__content {
      padding: 20px;
    }

    &__header-title {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 470px) {

    &__header {
      margin-bottom: 40px;

      &-title {
        font-size: 18px;
      }
    }

    &__form {

      &-errors {
        font-size: 14px;
      }

      &-controls {
        margin-top: 35px;
      }
    }
  }
}
