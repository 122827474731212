@import "styles/variables";

.button {
  padding: 0.7692em 1.5385em;
  background-color: $emphasize-color;
  border: none;
  border-radius: 14px;
  font-size: $em-font-size;
  text-transform: uppercase;
  color: $inverse-color;
  cursor: pointer;
  transition: background-color .2s ease-out;

  &:hover {
    background-color: lighten($emphasize-color, 10);
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}
