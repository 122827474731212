@import "styles/variables";
@import "styles/mixins";

.footer {
  min-height: 760px;
  color: $inverse-color;
  background-image: linear-gradient(to right, $secondary-color, $base-color);

  &__container {
    @include container;
    padding-top: 1px;
  }

  &__title {
    font-size: 44px;
    font-weight: $font-light;
    line-height: 0.91;
    text-align: center;
    margin: 32px 0 108px;
  }

  &__content {
    display: flex;
    justify-content: space-around;
    padding-top: 1px;
  }

  &__variants {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: $font-light;
    text-align: center;
    flex-basis: 40%;
    margin-top: 94px;

    &-text {
      font-size: 36px;
      width: 100%;

      &--small {
        font-size: $em-font-size;
        display: flex;
        justify-content: center;
        margin: 60px 0;
      }
    }

    &-item:first-child {
      margin-right: 120px;
    }

    &-bg {
      width: 728px;
      height: 600px;
      position: absolute;
      top: -190px;
      left: calc(50% - 330px);
      pointer-events: none;
      background: transparent url(/assets/bottom-bg-arrows.svg) center/contain no-repeat;
    }
  }

  &__benefits {
    flex-basis: 46%;

    &-item {
      display: flex;
      align-items: center;
      font-size: 36px;

      &:not(:last-child) {
        margin-bottom: 90px;
      }

      &-icon {
        width: 44px;
        height: 48px;
        margin-right: 40px;
        flex: 0 0 44px;
      }
    }
  }

  &__logo {
    text-align: center;
    margin-top: 20px;
  }

  @media screen and (max-width: 768px) {
    min-height: auto;
    padding: 1px 0;

    &__container {
      padding: 0 40px;
    }

    &__content {
      flex-direction: column-reverse;
    }

    &__title {
      font-size: 20px;
      margin: 40px 0 50px;
    }

    &__variants {

      &-bg {
        background-image: url(/assets/mobile-bg-arrows.svg);
        width: 223px;
        height: 227px;
        top: -25px;
        left: initial;
      }

      &-item:first-child {
        margin-right: 40px;
      }

      &-text {
        font-size: 18px;

        &--small {
          font-size: 1rem;
          margin: 38px 0 25px;
        }

        &:last-of-type {
          width: 300px;
          line-height: 1.33;
        }
      }
    }

    &__benefits {
      flex-basis: 46%;

      &-item {

        &:not(:last-child) {
          margin-bottom: 30px;
        }

        &-text {
          font-size: 1rem;
        }

        &-icon {
          width: 22px;
          height: 24px;
          flex: 0 0 22px;
        }
      }
    }

    &__logo {
      margin: 35px 0 25px;
    }
  }
}
