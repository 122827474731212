@import "styles/variables";
@import "styles/mixins";

.circle-graph {
  display: inline-flex;
  position: relative;
  @include squareSize(178px);

  &__value {
    @include stretchAbsolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $light-blue;

    &-prefix {
      font-size: 1.4rem;
      font-weight: $font-light;
    }

    &-num {
      font-size: 2.5rem;
    }

    &-suffix {
      font-size: 1.3rem;
    }
  }

  &__svg {
    transform: rotate(-90deg);

    &-bar {
      transition: stroke-dashoffset .4s ease-out;
    }
  }

  @media screen and (max-width: 768px) {
    &__value {

      &-prefix {
        font-size: 1rem;
      }

      &-num {
        font-size: 1.875rem;
      }

      &-suffix {
        font-size: 1rem;
      }
    }
  }
}
