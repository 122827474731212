@import "styles/variables";
@import "styles/mixins";

.regulations {
  margin-bottom: 150px;

  &__container {
    @include container;
    position: relative;
  }

  &__bg {
    width: 948px;
    max-width: 100%;
    height: 783px;
    position: absolute;
    top: -330px;
    left: calc(50% - 479px);
    pointer-events: none;
    background: transparent url(/assets/bg-arrows.svg) center/contain no-repeat;
  }

  &__content {
    display: flex;
    justify-content: center;
  }

  &__text {
    font-size: 36px;
    font-weight: $font-light;
    line-height: 1.39;
    color: $secondary-color;
  }

  &__text:first-child {
    max-width: 320px;
    text-align: right;
    margin-right: 132px;
  }

  &__text:last-child {
    max-width: 400px;
  }

  @media screen and (max-width: 1024px) {
    &__bg {
      left: initial;
      max-width: 95%;
    }

    &__text {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 1024px) {

    &__bg {
      top: -390px;
    }

    &__text {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 40px;

    &__text {
      position: relative;

      &:first-child {
        margin: 0 0 8px;
        width: 140px;
        right: 75px;
      }

      &:last-child {
        width: 168px;
        left: 55px;
      }
    }

    &__content {
      flex-direction: column;
      align-items: center;
    }

    &__bg {
      background-image: url(/assets/mobile-center-bg-arrows.svg);
      width: 320px;
      max-width: initial;
      height: 340px;
      left: calc(50% - 160px);
      right: 0;
      top: -70px;
    }
  }
}
