@import "styles/variables";
@import "styles/mixins";

.data-graphs {
  margin-bottom: 100px;

  &__container {
    @include container;
  }

  &__list {
    display: flex;
    justify-content: space-between;

    &-item {
      flex-basis: 16%;
      text-align: center;

      &-graph {
        margin-bottom: 28px;
        display: inline-flex;
      }

      &-text {
        font-weight: $font-light;
        line-height: 1.5;
        color: #233b6e;
      }
    }

    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;

      &-item {
        flex-basis: calc(50% - 30px);
        display: inline-flex;
        align-items: center;
        margin: 0 30px 30px 0;

        &-graph {
          flex: 0 0 170px;
          margin: 0 25px 0 0;
        }

        &-text {
          text-align: left;
        }
      }
    }

    @media screen and (max-width: 800px) {
      flex-wrap: nowrap;
      flex-direction: column;

      &-item {
        flex-basis: 100%;
        display: inline-flex;
        align-items: center;
        margin: 0 0 30px;

        &-graph {
          flex: 0 0 170px;
          margin: 0 25px 0 0;
        }

        &-text {
          text-align: left;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 80px;

    &__list {

      &-item {
        margin: 0;

        &:not(:last-child) {
          margin-bottom: 45px;
        }

        &-graph.circle-graph {
          flex-basis: 108px;
          height: 108px;
        }
      }
    }

  }
}
